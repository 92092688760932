<template>
  <section class="missingPage">
    <div class="container">
      <div class="missingPage__wrapper">
        <div class="missingPage__wrapper-content">
          <div class="missingPage__title">404</div>
          <div class="missingPage__subtitle">
            {{ $t('message.missingPage.textTop') }}
          </div>
          <div class="missingPage__subtitle">
            {{ $t('message.missingPage.textBottom') }}
          </div>
          <router-link :to="`/${$i18n.locale}/`" class="missingPage__btn">{{
            $t('message.missingPage.btn')
          }}</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'MissingPage'
}
</script>

<style lang="sass" scoped>
.missingPage
  display: flex
  flex-direction: column
  &__wrapper
    display: grid
    +media((grid-template-columns: (320: rem(15) 10fr rem(15), 768: 1fr 10fr 1fr)))
    &-content
      grid-column-start: 2
      text-align: center
  &__title
    color: $red
    +media((font-size: (320: rem(80), 768: rem(150))))
    font-weight: 700
    text-shadow: rem(1) rem(1) 0 $black
  &__subtitle
    font-weight: 500
    color: $black
    padding-bottom: rem(20)
    +media((font-size: (320: rem(15), 768: rem(20))))
  &__btn
    background: $red
    color: #fff
    font-style: normal
    font-weight: bold
    font-size: rem(18)
    line-height: rem(22)
    text-decoration: none
    transition: .5s
    width: rem(240)
    height: rem(68)
    border-radius: rem(4)
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    margin: auto
    margin-bottom: rem(20)
    &:hover
      background: $red-hover
</style>
